<template>
    <Loading title="Checking Credentials..." />
</template>

<script setup>
import { useRoute } from "vue-router";
import { useAuth0 } from '@auth0/auth0-vue';
import { onMounted, inject } from "vue";

const $auth0 = useAuth0()
const $env = inject("$env")
const route = useRoute()

onMounted(() => {
    const queryParams = new URLSearchParams(route.query).toString();
    let redirectUri = `${window.location.origin}/${route.query.target}?${queryParams}`;

    $auth0.loginWithRedirect({
        authorizationParams: {
            audience: $env.VUE_APP_AUTH0_API_AUDIENCE,
            redirect_uri: redirectUri
        }
    })
})
</script>

<style lang="css" scoped></style>
