import * as VueRouter from "vue-router";

import NotFound from "@/views/NotFound";
import HomeView from "@/views/HomeView";
import ManageAuth0Accounts from "@/views/admin/ManageAuth0Accounts";
import ManageValidationInfo from "@/views/admin/ManageValidationInfo";
import DealerView from "@/views/DealerView";
import IdentitiesView from "@/views/IdentitiesView";
import ContactView from "@/views/ContactView.vue";
import ValidationView from "@/views/ValidationView.vue";
import LogoutView from "@/views/LogoutView.vue";
import LoginMenuView from "@/views/LoginMenuView.vue";
import AuthorizedRedirectView from "@/views/AuthorizedRedirectView.vue";
import ManageDealers from "@/views/admin/ManageDealers.vue";
import InternalErrorView from "@/views/InternalError.vue";

export function createRouter(auth) {
  const routes = [
    {
      path: "/menu-login",
      name: "Login Menu",
      component: LoginMenuView
    },
    {
      path: "/authorized-redirect",
      name: "Authorized Redirect",
      component: AuthorizedRedirectView
    },
    {
      path: "/",
      name: "Home",
      component: HomeView,
      children: [
        {
          path: "/contact",
          name: "Contact Info",
          component: ContactView,
        },
        {
          path: "/dealer",
          name: "Dealer",
          component: DealerView,
        },
        {
          path: "/identities",
          name: "Linked Accounts",
          component: IdentitiesView,
        },
        {
          path: "/auth0-admin",
          name: "Auth0 Admin",
          component: ManageAuth0Accounts,
        },
        {
          path: "/validation-admin",
          name: "Validation Admin",
          component: ManageValidationInfo,
        },
        {
          path: "/dealers-admin",
          name: "Dealers Admin",
          component: ManageDealers,
        },
      ],
    },
    {
      path: "/validation",
      name: "validation",
      component: ValidationView,
    },
    {
      path: "/logout",
      name: "logout",
      component: LogoutView,
    },
    {
      path: "/internal-error",
      name: "Internal Error",
      component: InternalErrorView,
    },
    {
      path: "/profile",
      name: "profile",
      redirect: "/",
    },
    {
      path: "/:pathMatch(.*)*",
      name: "notfound",
      component: NotFound,
    },
  ];

  const history = VueRouter.createWebHistory(import.meta.env.BASE_URL);

  // const ogPush = Router.prototype.push;
  // Router.prototype.push = function push(location) {
  //   return ogPush
  //     .call(this, location)
  //     .catch((err) =>
  //       err.name === "NavigationDuplicated"
  //         ? Promise.resolve()
  //         : Promise.reject(err)
  //     );
  // };

  const router = VueRouter.createRouter({
    routes,
    history,
    scrollBehavior(to, _from, savedPosition) {
      if ("SelectFarm" === to.name && to.params.farmId) {
        const el = document.getElementById(
          `farm-accordion-${to.params.farmId}`
        );
        if (el) {
          el.scrollIntoView({ block: "nearest", behavior: "smooth" });
        }
      }

      return null;
    },
  });

  // router.beforeEach((to, from, next) => {
  //   authGuard(to, from, next);
  // });

  let userNameAppended = false;

  router.afterEach(function () {
    if (!userNameAppended && auth.isAuthenticated.value) {
      document.title += " - " + auth.user.value.name;
      userNameAppended = true;
    }
  });

  return router;
}
