<template>
  <div class="hamburger-menu">
    <button @click="toggleMenu" class="hamburger-icon">
      <span class="material-icons">{{ isOpen ? "close" : "menu" }} </span>
    </button>
    <div v-if="isOpen" class="menu-items">
      <ul>
        <li>
          <button @click="goHome">Home</button>
        </li>
        <li>
          <button @click="gotoContact">Contact Info</button>
          <p class="menu-description">
            Where we can reach you regarding your pickups
          </p>
        </li>
        <li>
          <button @click="gotoDealer">Dealer</button>
          <p class="menu-description">
            Select your Revolution Plastics dealer to access region-specific programs and features
          </p>
        </li>
        <li>
          <button @click="gotoIdentities">Linked Accounts</button>
          <span class="menu-description"
            >Link your accounts to access additional login options or retrieve past data</span
          >
        </li>
        <template v-if="props.isAdmin">
          <li>
            <button class="admin" @click="gotoValidationAdmin">
              Validation Admin
            </button>
          </li>
          <li>
            <button class="admin" @click="gotoAuth0Admin">Auth0 Admin</button>
          </li>
          <li>
            <button class="admin" @click="gotoDealersAdmin">
              Dealers Admin
            </button>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const isOpen = ref(false);

const callbackUrl = ref(null);
const props = defineProps({ isAdmin: { type: Boolean, default: false } });

function toggleMenu() {
  isOpen.value = !isOpen.value;
}
function closeMenu() {
  isOpen.value = false;
}
onMounted(() => {
  const split = window.location.href.split("?");
  if (split.length <= 1) {
    callbackUrl.value = null;
    return;
  }

  const paramString = split[1];
  let params = new URLSearchParams(paramString);
  for (let pair of params.entries()) {
    if (pair[0] === "callback") {
      callbackUrl.value = pair[1];
    } else {
      callbackUrl.value = null;
    }
  }
});

const callbackQuery = computed(() => {
  return callbackUrl.value ? { callback: callbackUrl.value } : {};
});

function goHome() {
  router.push({ path: "/", query: callbackQuery.value });
  closeMenu();
}

function gotoContact() {
  router.push({ path: "/contact", query: callbackQuery.value });
  closeMenu();
}

function gotoDealer() {
  router.push({ path: "/dealer", query: callbackQuery.value });
  closeMenu();
}

function gotoIdentities() {
  router.push({ path: "/identities", query: callbackQuery.value });
  closeMenu();
}
</script>
<style lang="css" scoped>
/* Hamburger Icon */
.hamburger-icon {
  position: relative;
  cursor: pointer;
  min-width: 4rem;
  z-index: 11;
}
.hamburger-icon span {
  font-size: 2rem;
}

/* Menu Items */
.menu-items {
  position: fixed;
  background-color: var(--bg1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  animation: fadeIn 0.3s ease;
  z-index: 10;
}
.menu-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-items li {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px 0;
}
.menu-items button {
  background-color: var(--bg1);
  color: var(--fg0);
  border: none;
  padding: 1rem;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.menu-description {
  font-size: 0.85rem;
  color: #777;
  margin: 0 var(--padding);
  line-height: 1.2;
}
.admin {
  color: #f27935 !important;
}
/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
