<template>
  <Loading v-if="$auth0.isLoading.value" title="Authenticating..." />
  <template v-else>
    <template v-if="isNonAuthenticatedRoute">
      <div class="wallpaper" />
      <router-view />
    </template>

    <template v-else-if="isAuthenticated">
      <div class="wallpaper" />
      <router-view />
    </template>
    <!-- Probaably should never reach here...-->
    <template v-else-if="displayLogin && !isAuthenticated && !isNonAuthenticatedRoute">
      <button @click="login">Login</button>
    </template>
  </template>
</template>

<script setup>
import { watch, computed, inject, ref } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute, useRouter } from "vue-router";

const $auth0 = useAuth0();
const $env = inject("$env");
const $telemetry = inject("$telemetry");
const route = useRoute();
const router = useRouter();
const displayLogin = ref(false);

const isAuthenticated = $auth0.isAuthenticated

const isNonAuthenticatedRoute = computed(() => {
  // The check for route.name is critical because for some reason the route starts
  // as an unnamed route of "/" to begin with.
  if (!route.name) {
    return true
  }

  return (route.path === '/validate') || (route.path === '/validation') || (route.path === '/menu-login')
    || (route.path === '/internal-error') || (route.path === '/authorized-redirect')
})

async function login() {
  const redirectUri = new URL('/', window.location.origin)

  await $auth0.loginWithRedirect({
    authorizationParams: {
      screen_hint: "login",
      audience: $env.VUE_APP_AUTH0_API_AUDIENCE,
      redirect_uri: redirectUri.toString()
    }
  })
}

watch(
  [$auth0.isAuthenticated, $auth0.isLoading, isNonAuthenticatedRoute, route],
  async () => {
    const query = route.query;
    if (Object.hasOwn(query, "state") && Object.hasOwn(query, "code")) {
      try {
        await $auth0.handleRedirectCallback(window.location.href);

        const newQuery = { ...query };
        delete newQuery.state;
        delete newQuery.code;

        router.replace({ path: router.path, query: newQuery });

        return;
      } catch (e) {
        $telemetry.error(e);
      } finally {
        displayLogin.value = true;
      }
    }

  if (isNonAuthenticatedRoute.value) {
    return
  }

  if ($auth0.isAuthenticated.value) {
    return
  }

  if ($auth0.isLoading.value) {
    return
  }

  router.replace({ path: '/menu-login' })
}, { immediate: true })
</script>

<style lang="css">
@import 'assets/style.css';

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* background-color: var(--bg0); */
  position: relative;
  z-index: 0;
  overflow: hidden;
}

@keyframes MattEffect {
  0% {
    transform: translate(0%, 0%) scale(1.4);
  }

  25% {
    transform: translate(5%, -10%) scale(1.6);
    /* Pan and zoom */
  }

  50% {
    transform: translate(15%, 5%) scale(1.5);
    /* Pan and zoom */
  }

  75% {
    transform: translate(-7%, -8%) scale(2.7);
    /* Pan and zoom */
  }

  100% {
    transform: translate(0%, 0%) scale(1.4);
    /* Return to original position */
  }
}

.wallpaper {
  animation: MattEffect 40s ease-in-out infinite;
  z-index: -1;
  will-change: transform;
  background-image: url('/topography.svg');
  background-color: rgb(12, 22, 21);
  background-repeat: repeat;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* width: 95vw;
  height: 50vh; */
  /* color: var(--fg0); */
  /* object-fit: fill; */
  /* border: none; */
}
</style>
