<template>
    <div>
        Internal Error -- Please Contact Our Support Team: <a href="tel:+15017720161">+1-501-772-0161</a>
    </div>
</template>

<script>
export default {
};
</script>

<style scoped>
div {
    background: var(--bg0);
    color: var(--fg0)
}
</style>