<template>
    <div v-if="count <= 0">
        --/--
    </div>
    <div v-else>
        <button class="mr-1" @click="left">&lt;</button>
        {{ page }} / {{ pageCount }}
        <button class="ml-1" @click="right">&gt;</button>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const page = defineModel({
    prop: 'page',
    event: 'update'
})

const props = defineProps({
    limit: {
        type: Number,
        required: true
    },
    count: {
        type: Number,
        required: false,
        default: 0
    }
})

const pageCount = computed(() => {
    return Math.ceil(props.count / props.limit);
})

function left() {
    page.value = Math.max(1, page.value - 1);
}

function right() {
    page.value = Math.min(pageCount.value, page.value + 1);
}
</script>

<style lang="css" scoped>
button {
    min-width: initial;
    width: 3rem !important;
    max-width: initial;
}
</style>