<template>
  <div class="home-top-div">
    <nav>
      <div class="nav-buttons">
        <img
          @click="gotoHome"
          src="@/assets/home_logo.svg"
          alt="Home"
          class="home-icon"
        />
        <span @click="gotoContact"  :class="{ active: isActive('/contact').value }">Contact Info</span>
        <span @click="gotoDealer" :class="{ active: isActive('/dealer').value }">Dealer</span>
        <span @click="gotoIdentities" :class="{ active: isActive('/identities').value }">Identities</span>
        <template v-if="isAdmin">
          <div
            class="admin-container"
            @mouseenter="showAdminOptions = true"
            @mouseleave="showAdminOptions = false"
          >
            <span>Admin</span>
            <div v-if="showAdminOptions" class="admin-options">
              <button @click="gotoValidationAdmin" class="admin">
                Validation Admin
              </button>
              <button @click="gotoAuth0Admin" class="admin">Auth0 Admin</button>
              <button @click="gotoDealersAdmin" class="admin">
                Dealers Admin
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="hamburger-menu">
        <HamburgerMenu :isAdmin="isAdmin" />
      </div>

      <span v-if="!callbackUrl" class="right">
        <router-link to="/logout">Logout</router-link>
      </span>

      <span v-if="callbackUrl" class="mobile-back-bttn">
        <button @click="exitInAppBrowser">
          <span class="material-icons">arrow_back</span>Back To App
        </button>
      </span>
    </nav>

    <div style="margin-top: 2rem">
      <CompanyLogo class="company-logo" />
    </div>

    <div v-if="!isNested" class="profile-summary" :class="{ nested: isNested }">
      <img v-if="rawJson?.picture" :src="rawJson.picture" alt="Profile picture" />

      <div v-if="!isNested">{{ rawJson.nickname }}</div>

      <template v-if="isNested">
        <span class="ml-1">
          <router-link to="/">Back</router-link>
        </span>
      </template>
    </div>

    <template v-else>
      <div style="flex-grow: 1">
        <router-view />
      </div>
    </template>

    <OtherAccountOptions v-if="!isNested" :verificationValue="verificationValue" style="align-self: stretch;" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter, useRoute } from "vue-router";
import OtherAccountOptions from "../components/OtherAccountOptions.vue";
import CompanyLogo from "../components/CompanyLogo.vue";
import HamburgerMenu from "../components/HamburgerMenu.vue";

const ROLES_URL = `https://deltaplastics.com/roles`;

const $auth0 = useAuth0();
const isAdmin = computed(() => {
  const user = $auth0.user?.value
  if (!user) {
    return false;
  }

  return user[ROLES_URL]?.includes("MyAccount Admin")
});

const rawJson = computed(() => $auth0?.user?.value?.rawJson || {});
const verificationValue = computed(() => {
  const isMobileUser = $auth0?.user?.value?.rawJson?.user_id.includes("sms");
  if (isMobileUser) {
    return rawJson.value?.phone_number.slice(2);
  }
  return rawJson.value?.email;
});

const callbackUrl = ref(null);
const showAdminOptions = ref(false);
const router = useRouter();
const route = useRoute();
onMounted(() => {
  const split = window.location.href.split("?");
  if (split.length <= 1) {
    callbackUrl.value = null;
    return;
  }

  const paramString = split[1];
  let params = new URLSearchParams(paramString);
  if (params.has("callback")) {
    callbackUrl.value = params.get("callback");
  } else {
    callbackUrl.value = null;
  }
});

const callbackQuery = computed(() => {
  return callbackUrl.value ? { callback: callbackUrl.value } : {};
});
function gotoHome() {
  router.push({ path: "/", query: callbackQuery.value });
}
function gotoContact() {
  router.push({ path: "/contact", query: callbackQuery.value });
}

function gotoDealer() {
  router.push({ path: "/dealer", query: callbackQuery.value });
}

function gotoIdentities() {
  router.push({ path: "/identities", query: callbackQuery.value });
}

function gotoValidationAdmin() {
  router.push({ path: "/validation-admin", query: callbackQuery.value });
  showAdminOptions.value = false;
}

function gotoAuth0Admin() {
  router.push({ path: "/auth0-admin", query: callbackQuery.value });
  showAdminOptions.value = false;
}

function gotoDealersAdmin() {
  router.push({ path: "/dealers-admin", query: callbackQuery.value });
  showAdminOptions.value = false;
}

const isNested = computed(() => {
  return route.matched.length > 1;
});

const nestedRoute = computed(() => {
  return route.matched.length > 1 ? route.matched[1] : null;
});
const isActive = (path) => computed(() => route.path === path);

function exitInAppBrowser() {
  if (route.query?.callback) {
    const winProxy = window.open(new URL(route.query?.callback), "_top");
    winProxy.close();
  } else if (callbackUrl.value) {
    window.location = callbackUrl.value;
  } else {
    window.open("", "_self").close();
  }
}

// Transfer ownership

// Link social accounts

// Change password

// Delete account
</script>

<style lang="css" scoped>
.home-icon {
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
}

.home-top-div {
  background: var(--bg1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - (2 * var(--padding)));
  height: calc(100% - (2 * var(--padding)));
  padding: var(--padding);
  border: 2px solid var(--bg0);
  box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.1);
  overflow: auto;
  /* transform: perspective(800px) matrix3d(1, -0.2, -0.2, 0, 0, 1, -0.2, 0, 0, 0, 1, -0.95, 0, 0, 0, 1); */

  /* background-color: red; */
  /* background-image: url('/topography.svg');  */
  /* filter: grayscale(100%) contrast(100%) brightness(180%); */
  /* border-radius: 50% 50% 50% 50%; */
  /* transform: perspective(1000px) scale(1.2) translateY(-50px) rotateX(45deg); */
  /* Outer glow */
}

.home-top-div:hover {
  box-shadow: 0px 6px 30px rgba(255, 255, 255, 0.3);
  transition: box-shadow 0.3s ease-in-out, border 0.8s ease-in-out;
  border: 2px solid var(--bg1);
  /* transform: perspective(500px)  rotateX(0.5deg) translateZ(1.5rem);
  transition: transform 0.3s ease-in-out; */
}

@keyframes CompanyLogoColorSwitch {
  0% {
    filter: drop-shadow(20px 20px 0 rgba(0, 0, 0, 0.5));
  }

  25% {
    filter: drop-shadow(20px 10px 0 rgba(0, 0, 0, 0.5));
  }

  50% {
    filter: drop-shadow(-10px 20px 0 rgba(0, 0, 0, 0.5));
  }

  75% {
    filter: drop-shadow(0 20px 0 rgba(0, 0, 0, 0.5));
  }

  100% {
    filter: drop-shadow(10px 20px 0 rgba(0, 0, 0, 0.5));
  }
}

.admin-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--padding);
}

.admin-container span {
  color: var(--fg1);
}

.admin-container a {
  text-decoration-color: var(--fg0);
}

.mobile-back-bttn button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--padding);
}
.hambuger-menu {
  display: block;
}

.company-logo {
  display: none;
}

.nav-buttons {
  display: none;
}

.nav-buttons span {
  margin: 0 var(--padding);
  cursor: pointer;
  color: var(--fg0);
}
.nav-buttons span:hover {
  text-decoration: underline;
}

.admin-container {
  position: relative;
  display: inline-block;
}

.admin-options {
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--bg1);
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.admin-options .admin {
  background: var(--bg0);
  border: none;
  padding: 8px 15px;
  text-align: left;
  cursor: pointer;
  width: 100%;
  color: var(--fg0);
}

.admin-options .admin:hover {
  background: var(--bg2);
}
nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  column-gap: var(--padding);
  background: var(--bg2);
  padding: var(--padding);
  align-items: center;
}
@media (min-width: 800px) and (min-height: 800px) {

  .active{
    text-decoration: underline;
  }

  .nav-buttons {
    display: flex;
    align-items: center;
  }
  .hamburger-menu {
    display: none;
  }

  .company-logo {
    display: block;
  }

  .home-top-div {
    min-width: 60vw;
    min-height: 50vh;
    width: initial;
    height: initial;
  }
  nav{
    background: transparent;
    padding: 0;
  }
}

button.admin {
  background: #F27935;
}


nav a {
  opacity: 1;
  animation: FadeIn 0.15s ease-in-out;
  color: var(--fg1);
}

.router-link-exact-active {
  font-style: italic;
  text-decoration: none;
  cursor: default;
}

.profile-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--fg0);
  transition: all 0.15s ease-in-out;
  flex: 1;
}

.profile-summary.nested {
  flex-direction: row !important;
  justify-content: flex-start !important;
  width: 100%;
}

.profile-summary img {
  width: 4rem;
  height: 4rem;
  transition: all 0.25s ease-in-out;
}

.profile-summary.nested img {
  width: 2rem;
  height: 2rem;
}

.profile-buttons {
  flex: 1;
  padding: var(--padding);
  background-color: var(--bg1);
  margin-top: calc(var(--padding) * 2);
  column-gap: calc(2 * var(--padding));
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: calc(2 * var(--padding));
  justify-content: center;
  align-items: center;
}

button:hover {
  cursor: pointer;
}
</style>
