<template>
    <div class="validation-top-div">
        <div class="wizard-container">
            <div class="progress-tracker mt-1">
                <div :class="{ step, active: index === currentStep }" v-for="(step, index) in steps" :key="index">
                    <!-- Circle with Step Number -->
                    <div class="circle" :class="{ completed: index < currentStep, active: index === currentStep }">
                        <span>{{ index + 1 }}</span>
                    </div>
                    <span class="title">{{ step.title }}</span>

                    <!-- Connecting Line -->
                    <div class="line" v-if="index < steps.length - 1" :class="{ completed: index < currentStep }"></div>
                </div>
            </div>

      <div class="current-step-div">
        <template v-if="currentStep === 0">
          <ContactForm v-model="userFormData" @submitForm="nextStep"/>
        </template>
        <template v-else-if="currentStep === 1">
          <DealerSearch
            v-model:dealer-id="userFormData.dealerId"
            v-model:prev-next-available="prevNextAvailable"
          />
        </template>
        <template v-else-if="currentStep === 2">
          <ReviewForm
            :userFormData="userFormData"
            :setStep="setStep"
          />
        </template>
      </div>
      <div class="nav-bttns" v-if="prevNextAvailable">
        <button
          :style="{ visibility: currentStep > 0 ? 'visible' : 'hidden' }"
          @click="prevStep"
        >
          Previous
        </button>
        <button v-if="currentStep < steps.length - 1 && currentStep !== 0" @click="nextStep" :disabled="!isStepValid">
          Next
        </button>

                <template v-if="currentStep === (steps.length - 1)">
                    <form action="user-validation" method="POST">
                        <input type="hidden" name="session_token" :value="route.query.session_token">
                        <input type="hidden" name="firstName" :value="userFormData.firstName">
                        <input type="hidden" name="lastName" :value="userFormData.lastName">
                        <input type="hidden" name="address" :value="userFormData.address">
                        <input type="hidden" name="address2" value="">
                        <input type="hidden" name="city" :value="userFormData.city">
                        <input type="hidden" name="state" :value="userFormData.state">
                        <input type="hidden" name="zip" :value="userFormData.zip">
                        <input type="hidden" name="dealerId" :value="userFormData.dealerId">
                        <input type="hidden" name="org" :value="userFormData.org">

            <input
              v-if="phoneNumber"
              name="phone"
              type="hidden"
              :value="phoneNumber"
            />

            <button
              type="submit"
              :disabled="!isFormCompleted"
            >
              Submit</button>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted, reactive } from "vue";
import { jwtDecode } from "jwt-decode";
import { useRoute } from "vue-router";

import ContactForm from "../components/ContactForm.vue";
import DealerSearch from "../components/DealerSearch.vue";
import ReviewForm from "../components/ReviewForm.vue";

const route = useRoute();
const currentStep = ref(0);
const prevNextAvailable = ref(true);

const steps = [
    { title: "Update Contact"},
    { title: "Select Dealer"},
    { title: "Review" },
];
const nextStep = () => {
    if (currentStep.value < steps.length - 1) currentStep.value++;
};
const prevStep = () => {
    if (currentStep.value > 0) currentStep.value--;
};
const setStep = (stepNum) => {
    currentStep.value = stepNum;
};

onMounted(() => {
    const handlePopState = (event) => {
        window.history.pushState(null, "", window.location.href);

        // Block the back button
        if (currentStep.value === 0) {
            alert("Back navigation is disabled.");
        }
        else if (prevNextAvailable.value) {
            prevStep();
        }
    };

    // Push a state to detect back navigation
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => window.removeEventListener("popstate", handlePopState);
});

const userFormData = reactive({
    firstName: "",
    lastName: "",
    address: "",
    zip: "",
    city: "",
    state: "",
    org: "",
    dealerId: null,
});


const isStepValid = computed(() => {

  if (currentStep.value === 1) {
    return userFormData.dealerId != null && userFormData.dealerId !== "";
  }
  return false;
});

const priorValidationInformation = computed(() => {
    const encodedValidationInformation = route.query.validation_info;

    if (encodedValidationInformation) {
        try {
            return JSON.parse(encodedValidationInformation);
        } catch (error) {
            return {};
        }
    }

    return {};
});

const userFromSessionToken = computed(() => {
    const sessionToken = route.query.session_token;

    if (sessionToken) {
        try {
            return jwtDecode(sessionToken)?.user || {};
        } catch (error) {
            return {};
        }
    }

    return {};
});

const phoneNumber = computed(() => {
    const user = userFromSessionToken.value

    const smsIdentity = user.identities?.find((identity) => identity.provider === "sms");

    if (smsIdentity) {
        return smsIdentity.profileData?.phone_number;
    }

    return null
});

const isFormCompleted = computed(() => {
  const { firstName, lastName, address, zip, city, state, org, dealerId } =
    userFormData;
  const requiredFields = [
    firstName,
    lastName,
    address,
    zip,
    city,
    state,
    org,
    dealerId,
  ];

  const allFieldsFilled = requiredFields.every(
    (field) => field && field.trim() !== ""
  );

  return allFieldsFilled 
});

watch(
    priorValidationInformation,
    (newVal) => {
        userFormData.firstName = newVal?.first_name ?? "";
        userFormData.lastName = newVal?.last_name ?? "";
        userFormData.address = newVal?.address1 ?? "";
        userFormData.city = newVal?.city ?? "";
        userFormData.state = newVal?.state ?? "";
        userFormData.zip = newVal?.zip ?? "";
        userFormData.dealerId = newVal?.dealer_id ?? "";
        userFormData.org = newVal?.organization ?? "";
    },
    { immediate: true }
);
</script>

<style lang="css" scoped>
/* Stolen from HomeView... */
.validation-top-div {
    background: var(--bg2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - (2 * var(--padding)));
    height: calc(100% - (2 * var(--padding)));
    padding: var(--padding);
    border: 2px solid var(--bg0);
    box-shadow: 0px 0px 124px 15px rgba(255, 255, 255, 0.25);
    overflow: auto;
    color: var(--fg0);
    position: relative;
}

.validation-top-div:hover {
    box-shadow: 0px 0px 124px 15px rgba(255, 255, 255, 0.45);
    transition: box-shadow 0.3s ease-in-out;
    border: 2px solid var(--bg2);
    transition: border 0.8s ease-in-out;
}

.wizard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem 0rem;
    min-height: 30rem;
    width: 100%;
    height: 100%;
    background-color: var(--bg2);
    border-radius: 2px;
}

.progress-tracker {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    font-weight: regular;
}

.step.active {
    font-weight: bold;
}

.circle {
    width: 3rem;
    height: 3rem;
    border: 4px solid #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: regular;
    font-weight: bold;
    background-color: var(--bg2);
    color: var(--fg0);
    z-index: 1;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.circle.completed {
    background-color: var(--revBlue);
    border-color: var(--revBlue);
    color: white;
}

.circle.active {
    background-color: var(--bg2);
    border-color: var(--revBlue);
    color: var(--fg0);
}

.title {
    margin-top: calc(2 * var(--padding));
    font-size: 12px;
    text-align: center;
    position: absolute;
    top: 45px;
}

.line {
    position: absolute;
    top: 50%;
    /* Align with circle center */
    left: 50%;
    right: -50%;
    /* Extend to the next step */
    height: 3px;
    background-color: #ccc;
    z-index: 0;
    transition: background-color 0.3s;
}

.line.completed {
    background-color: var(--revBlue);
}

.current-step-div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.nav-bttns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
}

@media (min-width: 800px) and (min-height: 800px) {
    .validation-top-div {
        min-width: 50vw;
        min-height: 50vh;
        width: initial;
        height: initial;
    }
}
</style>
