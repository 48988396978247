<template>
  <Loading title="Loading..." v-if="loading" />
  <div class="top" v-show="dealers">
    <div class="mb-1 search-bar">
      <div>
        <label class="note"
          >Search the customer-entered validation info (address, org,
          etc.)</label
        >
      </div>
      <label for="user-search" class="mr-1">Search:</label
      ><input
        v-model="search.searchText"
        type="text"
        id="user-search"
        placeholder="..."
        @keyup.enter="doSearch"
        autofocus
      />
    </div>

    <div v-if="dealers && search.searchResults" class="search-results">
      <!-- <Auth0User v-for="user in searchResults" :key="user.key" :user="user" @changed="doSearch(2750)" /> -->
      <AdminValidationForm
        v-for="info in search.searchResults"
        :key="info.id"
        :info="info"
        :states="states"
        :dealers="dealers"
      >
        {{ info }}
      </AdminValidationForm>
    </div>
  </div>
</template>

<script setup>
import AdminValidationForm from "@/components/AdminValidationForm.vue";
import { computed, onBeforeMount, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useAdminApi } from "@/plugins/api";

const route = useRoute();
const $adminApi = useAdminApi();

const DefaultDealers = [
  { id: "00000000-0000-0000-0000-000000000000", name: "No Dealer" },
  { id: "11111111-1111-1111-1111-111111111111", name: "Unlisted Dealer" },
];

const states = computed(() => {
  return [
    "AK",
    "AL",
    "AR",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VA",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY",
  ];
});

const loading = ref(false);
const search = reactive({ searchText: "", searchResults: null });
const dealers = ref(null);

async function fetchDealer() {
  loading.value = true;
  let theDealers = await $adminApi.getAllDealers();
  theDealers = theDealers.results.filter(
    (dealer) =>
      dealer.id !== "00000000-0000-0000-0000-000000000000" &&
      dealer.id !== "11111111-1111-1111-1111-111111111111"
  );
  theDealers.sort((a, b) => (a.name||"").localeCompare(b.name||""));

  dealers.value = [...DefaultDealers, ...theDealers];
  loading.value = false;
}

async function fetchData() {
  if (search.searchText?.length) {
    loading.value = true;
    search.searchResults = await $adminApi.getUsers(search.searchText);
    loading.value = false;
  } else {
    search.searchResults = [];
  }
}

async function doSearch() {
  console.log(search.searchText);
  try {
    await fetchData();
  } catch (e) {
    console.error(e);
  }
}

onBeforeMount(() => {
  search.searchText = route.query.q || "";
});

onMounted(() => {
  fetchData();
  fetchDealer();
});
</script>

<style lang="css" scoped>
.top {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
}

.note {
  font-style: italic;
  font-size: smaller;
}

.search-bar {
  line-height: 2.2em;
}

.search-results {
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-bottom: var(--padding);
  display: flex;
  flex-direction: column;
  overflow: auto;
  row-gap: var(--padding);
}
</style>
