<template>
  <div class="top-div">
    <Loading v-if="dealerSwrv.isValidating.value" />

    <div v-if="!searching" class="not-searching-div">
        <summary v-if="IsNoDealer">You have no dealer selected.</summary>
        <summary v-else-if="IsUnlistedDealer">
          <p>You have a dealer that is not in our records.</p>
          <p>
            Please choose another dealer or contact support if you believe this
            is incorrect:
            <a href="tel:+15017720161">+1-501-772-0161</a>
          </p>
        </summary>
        <summary v-else-if="IsValidDealer">
          Thank you for choosing Revolution plastics.
        </summary>
        <summary v-else-if="IsValidDealerButNotRebateEligible">
          Your dealer
        </summary>
      

      <fieldset v-if="myDealer.value" class="my-dealer mt-2">
        <legend>Selected Dealer</legend>

        <div class="my-dealer-info">
          <h3>{{ myDealer.value.name }}</h3>
          <div class="my-dealer-address" v-if="IsValidDealer">
            <span v-if="myDealer?.value?.address">{{
              myDealer.value.address
            }}</span>
            <span v-if="myDealer?.value?.city">{{
              myDealer?.value?.city
            }}</span>
            <span v-if="myDealer?.value?.zip5">{{
              myDealer?.value?.zip5
            }}</span>
          </div>
        </div>
      </fieldset>

      <div class="bttn-container mt-3" v-if="!IsDealerNull">
        <button @click="startSearching">
           Change Dealer
        </button>
      </div>
    </div>

    <div v-else class="searching-div mt-1">
      <div class="current-dealer-div" v-if="myDealer.value">
        Current Dealer: {{ myDealer.value.name }}
      </div>

      <div class="mt-3 searching-inner-div">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              id="zip"
              v-model="data.zip"
              @input="zipChanged"
              placeholder="Zip code"
              maxlength="5"
            />
          </div>
          <span style="font-weight: bold">OR</span>
          <div class="input-group">
            <input
              type="text"
              id="city"
              @input="cityChanged"
              placeholder="City name"
            />
          </div>
          <span style="font-weight: bold">OR</span>
          <button type="button" @click="useMyLocation">Use My Location</button>
        </div>

        <div
          v-if="data.gettingCurrentPosition || dealersSwrv.isValidating.value"
          class="spinner-container"
        >
          <div class="spinner" />
        </div>
        <div v-else-if="dealersSwrv.data.value" class="results-container">
          <h3 class="no-dealers">
            {{
              dealersSwrv.data.value?.length ? "Results" : "No Dealers Found"
            }}
          </h3>
          <ul v-if="dealersSwrv.data.value?.length" class="results-list">
            <li
              class="result-item"
              v-for="dealer in dealersSwrv.data.value"
              :key="dealer?.id"
              @click="selectDealer(dealer)"
            >
              <span class="dealer-name" :title="dealer.name">{{ dealer?.name }}</span> <span class="dealer-loc">{{ dealer?.address?.trim() }} {{ dealer?.city?.trim() }}</span>
            </li>
          </ul>
        </div>
        <div class="content-fill" v-else>
          Enter your dealer's ZIP code or city name, or use your current
          location above to find your dealer.<span class="material-icons"
            >search</span
          >
        </div>
      </div>

      <div class="bttn-group mb-1">
        <button
          class="no-dealer-bttn"
          type="button"
          @click="noDealerClicked"
          title="If you do not buy Revolution plastics, select this option."
        >
          No Dealer
        </button>

        <button
          :disabled="dealersUrl?.value?.length"
          @click="dealerNotFoundClicked"
        >
          Dealer Not Found
        </button>

        <button v-if="!IsDealerNull" class="cancel-bttn" type="button" @click="stopSearching">
          Cancel Update
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import useSwrv from "swrv";
import debounce from "lodash.debounce";

const dealerId = defineModel("dealer-id");
const prevNextAvailable = defineModel("prev-next-available");

const props = defineProps({
  handleSubmit: {
    type: Function,
    Required: false,
  },
  inWizard: {
    type: Boolean,
    Required: false,
  },
});

const data = reactive({
  zip: "",
  city: "",
  position: null,
  gettingCurrentPosition: false,
});
const searching = ref(false);

function startSearching() {
  searching.value = true;
  prevNextAvailable.value = false;
}
function stopSearching() {
  searching.value = false;
  prevNextAvailable.value = true;
}
function noDealerClicked() {
  dealerId.value = NO_DEALER.id;
  if (props.handleSubmit) {
    props.handleSubmit();
  }
  stopSearching();
}
function dealerNotFoundClicked() {
  dealerId.value = UNLISTED_DEALER.id;
  if (props.handleSubmit) {
    props.handleSubmit();
  }
  stopSearching();
}

async function JsonFetcher(...params) {
  if (!params[0]?.length) {
    return null;
  }

  const res = await fetch(...params);
  return await res.json();
}

const dealerUrl = computed(() => {
  if (dealerId.value) {
    return `/api/dealers/${dealerId.value}`;
  }
});

const dealerSwrv = useSwrv(dealerUrl, JsonFetcher, {
  revalidateOnFocus: false,
});
const myDealer = computed(() => dealerSwrv.data || null);

const NO_DEALER = {
  id: "00000000-0000-0000-0000-000000000000",
  name: "No Dealer",
};
const UNLISTED_DEALER = {
  id: "11111111-1111-1111-1111-111111111111",
  name: "Unlisted Dealer",
};

const debouncedCityChanged = debounce((value) => {
  data.city = value;
}, 400);

// Clean out the other values if one is updated
function cityChanged(newVal) {
  data.zip = "";
  data.position = null;

  debouncedCityChanged(newVal.target.value);
}

function zipChanged() {
  data.city = "";
  data.position = null;
}

const IsDealerNull = computed(() => dealerId.value === (null || ""));
const IsNoDealer = computed(() => dealerId.value === NO_DEALER.id);
const IsUnlistedDealer = computed(() => dealerId.value === UNLISTED_DEALER.id);
const IsValidDealer = computed(
  () =>
    myDealer.value &&
    myDealer.value !== NO_DEALER.id &&
    myDealer.value !== UNLISTED_DEALER.id
);

const dealersUrl = computed(() => {
  const ZipRegex = /^\d{5}$/;

  if (ZipRegex.test(data.zip)) {
    return `/api/dealers/zipcode/${data.zip}`;
  }
  if (data.city?.length && data.city.length > 1) {
    return `/api/dealers/city/${data.city}`;
  }
  if (data.position) {
    return `/api/dealers/position/${data.position.latitude},${data.position.longitude}`;
  }

  return null;
});

const dealersSwrv = useSwrv(dealersUrl, JsonFetcher, {
  revalidateOnFocus: false,
  revalidateDebounce: 250,
});

function selectDealer(dealer) {
  dealerId.value = dealer.id;
  if (props.handleSubmit) {
    props.handleSubmit();
  }

  stopSearching();
}

function useMyLocation() {
  if (!("geolocation" in navigator)) {
    alert("Geolocation is not available in your browser.");
    return;
  }

  data.zip = "";
  data.city = "";
  data.gettingCurrentPosition = true;

  navigator.geolocation.getCurrentPosition(
    (geoPosition) => {
      data.gettingCurrentPosition = false;

      data.position = {
        latitude: geoPosition.coords.latitude,
        longitude: geoPosition.coords.longitude,
      };
    },
    (error) => {
      data.gettingCurrentPosition = false;
      alert(error.message);
    }
  );
}
onMounted(() => {
  if (IsDealerNull.value) {
    startSearching();
  }
});
</script>

<style lang="css" scoped>
.top-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 32rem;
}

fieldset {
  background: var(--bg1);
  min-width: 30rem;
  color: var(--fg1);
}
fieldset legend {
  color: var(--fg1);
}

.no-dealer {
  text-align: center;
}

.cancel-bttn {
  background-color: var(--accent2);
}

.my-dealer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem 0;
  /* gap: 0 4rem; */
  /* position: relative; */
  padding: var(--padding);
}

.my-dealer-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-dealer-address {
  display: flex;
  gap: 0 1rem;
}

.bttn-container {
  display: flex;
  justify-content: center;
  gap: 0 1rem;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0 1rem;
  margin-bottom: 2rem;
}
.form-group span {
  color: var(--fg0);
}

.not-searching-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.not-searching-div summary{
  color: var(--fg0);
}
.content-fill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--fg0);
  width: 100%;
  gap: var(--padding);
}
.content-fill span {
  font-size: 3rem;
}

.searching-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.searching-inner-div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.current-dealer-div {
  width: 100%;
  color: var(--accent2);
  text-align: left;
  font-size: smaller;
  font-style: italic;
}

.results-container {
  width: 100%;
  flex-grow: 1;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 0.5s ease-out forwards;
  /* Animation */
}

.results-container.show {
  opacity: 1;
  transform: translateY(0);
}

.results-list {
  background-color: var(--bg0);
  max-height: 26rem;
  border: 2px solid var(--fg1);
  overflow: auto;
}

.result-item {
  color: var(--fg0);
  padding: 0.5rem;
  cursor: pointer;
  font-size: smaller;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.result-item:hover {
  background-color: var(--bg2);
}

.result-item .dealer-name {
  text-wrap: nowrap;
}

.result-item .dealer-loc {
  font-style: italic;
  font-size: smaller;
  text-wrap: nowrap;
}

.no-results {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem 0;
  margin-bottom: 1rem;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 1rem;
}

.bttn-group {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding) var(--padding);
  justify-content: center;
}

.no-dealer-bttn {
  background-color: rgb(149, 23, 23);
}

ul {
  list-style-type: none;
  padding: 0;
}

h3 {
  margin: 0.5rem 0;
}

#zip {
  width: 5rem;
}

.no-dealers {
  text-align: center;
  font-style: italic;
  color: var(--fg0);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  fieldset {
    min-width: 15rem;
  }
  .my-dealer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem 0;
    position: static;
  }

  h3 {
    text-align: center;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem 0;
    width: 100%;
  }

  .input-group {
    text-align: center;
  }
  .results-container{
    width: 90vw;
  }

  #zip {
    width: 100%;
  }
}
</style>
