<template>
  <div class="container">
    <Loading v-if="loadingMyData" title="Getting User Information" />
    <Loading v-if="submittingForm" title="Submitting" />

    <div class="phone-container">
      <label for="phone">Phone</label>
      <input
        type="tel"
        id="phone"
        placeholder="Enter your phone #"
        v-model="phoneNumber"
        required
        disabled
      />
      <button type="button" @click="linkNewSmsIdentity">
        <span class="material-icons">edit</span>
      </button>
    </div>

    <hr />

    <ContactForm
      v-model="form"
      :wizardMode="false"
      @submitForm="handleSubmit"
    />
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed, inject, watch } from "vue";
import { useApi } from "../plugins/api";
import { useAuth0 } from "@auth0/auth0-vue";
import { createAuth0Client } from "@auth0/auth0-spa-js";

import ContactForm from "../components/ContactForm.vue";

const $api = useApi();
const $auth0 = useAuth0();
const $telemetry = inject("$telemetry");
const $env = inject("$env");

class DummyCache {
  constructor() {
    this.vals = {};
  }
  set(key, entry) {
    this.vals[key] = entry;
  }
  get(key) {
    return this.vals[key];
  }
  remove(key) {
    delete this.vals[key];
  }
  allKeys() {
    return Object.keys(this.vals);
  }
}

const form = reactive({
  firstName: "",
  lastName: "",
  address: "",
  zip: "",
  city: "",
  state: "",
  phone: "",
  org: "",
  dealerId: "",
});

const prevUserInfo = reactive({
  firstName: "",
  lastName: "",
  address: "",
  zip: "",
  city: "",
  state: "",
  phone: "",
  org: "",
  dealerId: "",
});

const loadingMyData = ref(false);
const submittingForm = ref(false);


const phoneIdentity = computed(() => {
  const rawJson = $auth0?.user?.value?.rawJson || {};

  const identities = rawJson?.identities || [];
  const linkedIdentities = identities.filter(
    (i) => rawJson.user_id?.indexOf(i.userId) < 0
  );

  // add some metadata

  linkedIdentities.forEach((i) => {
    i.name =
      i.profileData?.email || i.profileData?.name || i.profileData?.first_name;

    i.identifier = i.profileData?.email || i.profileData?.phone_number;
  });

  const phoneIdentity =
    linkedIdentities.find((identity) => identity.connection === "sms") || null;

  return phoneIdentity;
});
const phoneNumber = computed(() => {
  return phoneIdentity.value?.name.slice(2) || "n/a";
});

const getMyDealer = async () => {
  loadingMyData.value = true;
  try {
    const response = await $api.getUserInformation();

    const userInfo = {
      firstName: response.userInformation.first_name,
      lastName: response.userInformation.last_name,
      address: response.userInformation.address1,
      zip: response.userInformation.zip,
      city: response.userInformation.city,
      state: response.userInformation.state || "",
      phone: phoneNumber.value,
      org: response.userInformation.organization,
      dealerId: response.userInformation.dealer_id,
    };
    Object.assign(form, userInfo);
    Object.assign(prevUserInfo, userInfo);
  } catch (e) {
    console.error(e);
  }
  loadingMyData.value = false;
};

const linkNewIdentity = async (connection) => {
  try {
    const $linkedAuth0 = await createAuth0Client({
      domain: $env.VUE_APP_AUTH0_DOMAIN,
      clientId: $env.VUE_APP_AUTH0_CLIENT_ID,
      cache: new DummyCache(),
      legacySameSiteCookie: false,
      useCookiesForTransactions: false,
      useRefreshTokens: false,
    });

    await $linkedAuth0.loginWithPopup({
      authorizationParams: {
        audience: $env.VUE_APP_AUTH0_API_AUDIENCE,
        connection,
        prompt: "login",
        "rev-linking": "true",
      },
    });

    const token = await $linkedAuth0.getTokenSilently({
      authorizationParams: {
        audience: $env.VUE_APP_AUTH0_API_AUDIENCE,
      },
    });

    await $api.postIdentity(token);

    await $auth0.getAccessTokenSilently({ cacheMode: "off" });
  } catch (e) {
    if (e?.error === "cancelled") {
      return;
    }

    $telemetry.error(e);
  }
};
const unlinkIdentity = async (identity) => {
  if (!identity) {
    return;
  }
  try {
    await $api.deleteIdentity(identity.connection, identity.userId);

    await $auth0.getAccessTokenSilently({ cacheMode: "off" });
  } catch (e) {
    console.error(e);
  }
};

const linkNewSmsIdentity = async () => {
  await unlinkIdentity(phoneIdentity.value);
  await linkNewIdentity("sms");
  form.phone = phoneNumber;
  await handleSubmit();
};
const handleSubmit = async () => {
  try {
    submittingForm.value = true;
    await $api.putUserInformation(form);
    Object.assign(prevUserInfo, form);
  } catch (e) {
    console.error(e);
  } finally {
    submittingForm.value = false;
  }
};

onMounted(async () => {
  try {
    await getMyDealer();
  } catch (e) {
    console.error(e);
  }
});
</script>

<style lang="css" scoped>
.container {
  color: var(--fg0);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.phone-container {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: var(--padding) var(--padding);
  align-items: center;
  height: 2rem;
}
.phone-container button {
  height: 100%;
  min-width: 3rem;
  padding: 0;
}
.phone-container input {
  height: 100%;
  width: 12rem;
  box-sizing: border-box;
}

@media only screen and (max-width: 800px) {
  .form-container {
    width: calc(100% - (2 * var(--padding)));
  }
}
</style>
