<template>
  <div class="top">
    <div>
      <form @submit.prevent="doSearch" class="dealer-filters">
        <span class="input-label">
          <input type="text" name="name" id="dealer-name" placeholder="Name..." v-model="search.name" autoc>
          <label for="dealer-name">Name:</label>
        </span>

        <span class="input-label">
          <input type="text" name="address" id="address" placeholder="Address..." v-model="search.address">
          <label for="address">Address:</label>
        </span>

        <span class="input-label">
          <input type="text" name="city" id="city" placeholder="City..." v-model="search.city">
          <label for="address">City:</label>
        </span>

        <span class="input-label">
          <input type="text" name="zip" id="zip" placeholder="Zip..." v-model="search.zip5" size="6">
          <label for="address">Zip:</label>
        </span>

        <span class="input-label">
          <input type="text" name="cust_num" id="cust_num" placeholder="SL Cust Num..." v-model="search.sl_cust_num" size="6">
          <label for="address">SL Cust Num:</label>
        </span>

        <button type="submit">Search</button>
      </form>
    </div>
    <div class="search-results">
      <Loading v-if="dealerSwrv?.isValidating.value" />

      <table>
        <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th>City</th>
          <th>Zip</th>
          <th>Cust Num</th>
          <th>Cust Seq</th>
          <th>Rebate Eligible</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!dealerSwrv?.data?.value?.results?.length">
          <td colspan="7" style="text-align: center;font-style: italic;">--No Dealers Found--</td>
        </tr>

        <template v-for="d in dealerSwrv?.data?.value?.results || []" :key="d.id">
          <tr class="dealer-row">
            <td>{{ d.name }}</td>
            <td>{{ d.address }}</td>
            <td>{{ d.city }}</td>
            <td>{{ d.zip5 }}</td>
            <td>{{ d.sl_cust_num }}</td>
            <td>{{ d.sl_cust_seq }}</td>
            <td style="text-align: center;">
              <input type="checkbox" :checked="d.rebate_eligible"
                @change="flipRebateEligible(d.id, d.rebate_eligible)" />
            </td>
          </tr>
        </template>
      </tbody>
      </table>
    </div>
    <div>
      <pagination-nav v-model="pagination.page" :limit="pagination.limit"
        :count="dealerSwrv?.data?.value?.count || 0" />
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, computed, ref } from "vue";
import { useAdminApi } from "@/plugins/api";
import useSwrv from "swrv";
import debounce from "lodash.debounce";

const $adminApi = useAdminApi();

const search = reactive({
  name: "", city: "", address: "", zip5: "", sl_cust_num: ""
});

const pagination = reactive({
  page: 1, limit: 10
})

async function JsonFetcher(...params) {
  if (!params[0]) {
    return []
  }

  const res = await $adminApi.fetch(...params);

  return await res.json();
}

const dealersUrl = ref(null)

function updateDealersUrl() {
  const params = new URLSearchParams({
    ...search, ...pagination
  })

  dealersUrl.value = `/api/dealers?${params.toString()}`;
}

const debouncedUpdateDealersUrl = debounce(updateDealersUrl, 700);
const shortDebouncedUpdateDealersUrl = debounce(updateDealersUrl, 200);
const debouncedMutateDealersSwrv = debounce(() => dealerSwrv.mutate(), 700);

watch([search], () => {
  pagination.page = 1;
  debouncedUpdateDealersUrl()
});

watch([pagination], () => {
  shortDebouncedUpdateDealersUrl()
}, { immediate: true });

const dealerSwrv = useSwrv(dealersUrl, JsonFetcher, {
  revalidateOnFocus: false, revalidateIfStale: false,
  revalidateDebounce: 700
});

function flipRebateEligible(id, current) {
  $adminApi.putDealer(id, { rebate_eligible: !current });

  debouncedMutateDealersSwrv()
}
</script>

<style lang="css" scoped>
.top {
  display: flex;
  flex-direction: column;
  row-gap: var(--padding);
  height: 100%;
  align-items: center;
}

.search-results {
  position: relative;
  max-width: 50em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: var(--padding);
  background-color: var(--bg1);
}

.search-results div {
  background: var(--bg2);
  padding: 0.25em;
}

.dealer-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: var(--padding);
  row-gap: var(--padding);
  max-width: 50em;
}

th {
  text-decoration: underline;
  font-style: italic;
  background: var(--bg2);
}

td {
  background: var(--bg2);
}

.dealer-row:hover {
  text-decoration: underline;
}

.dealer-row:hover  td {
  background-color: var(--bg1);
}

input[type="checkbox"] {
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
}

.input-label {
  display: flex;
  flex-direction: row-reverse;
  column-gap: var(--padding);
  align-items: center;
}

input:placeholder-shown+label {
  display: none;
}
</style>
