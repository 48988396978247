<template>
  <form
    class="contact-form-container"
    @submit.prevent="handleSubmit"
  >
    <div class="form-group">
      <div>
        <label for="firstName">First Name</label>
        <input
          type="text"
          id="firstName"
          placeholder="Enter your first name"
          v-model="form.firstName"
          required
          @invalid="markInvalid"
          @input="clearInvalid"
        />
      </div>
      <div>
        <label for="lastName">Last Name</label>
        <input
          type="text"
          id="lastName"
          placeholder="Enter your last name"
          v-model="form.lastName"
          required
          @invalid="markInvalid"
          @input="clearInvalid"
        />
      </div>
    </div>

    <hr />

    <div class="form-group">
      <label for="billing-address">Billing Address</label>
      <input
        type="text"
        id="billing-address"
        placeholder="Enter your billing address"
        v-model="form.address"
        required
        size="28"
        @invalid="markInvalid"
        @input="clearInvalid"
      />
    </div>

    <div class="form-group">
      <div class="city-div">
        <label for="city">City</label>
        <input
          type="text"
          id="city"
          placeholder="Enter your city"
          v-model="form.city"
          required
          @invalid="markInvalid"
          @input="clearInvalid"
        />
      </div>
      <div style="display: flex">
        <div class="state-div">
          <label for="state">State</label>
          <input
            id="state"
            v-model="form.state"
            @focus="showDropdown = true"
            @blur="onBlur"
            minlength="2"
            maxlength="2"
            size="2"
            required
            pattern="\S+"
            @invalid="markInvalid"
            @input="clearInvalid"  
          />
          <ul v-if="showDropdown" class="dropdown-menu">
            <li
              v-for="(state, index) of filteredStates"
              :key="index"
              @click="selectState(state)"
            >
              {{ state }}
            </li>
            <li v-if="filteredStates.length === 0">No results found</li>
          </ul>
        </div>

        <div class="zip-div">
          <label for="zip">Zip</label>
          <input
            type="text"
            id="zip"
            size="8"
            v-model="form.zip"
            maxlength="5"
            pattern="^\d{5}$"
            required
            @invalid="markInvalidZip"
            @input="clearInvalid"
          />
        </div>
      </div>
    </div>

    <hr />

    <div class="form-group">
      <label for="org">Organization (Name of Farm/Business)</label>
      <input
        type="text"
        id="org"
        placeholder="Enter your organization"
        v-model="form.org"
        required
        @invalid="markInvalid"
        @input="clearInvalid"
      />
     
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
      "
      class="mt-2"
    >
    <button type="submit">Update</button>
    </div>

    <!-- <div class="form-group">
      <label for="billingAddress">Billing Address</label>
      <input class="address-input" type="text" id="billingAddress" placeholder="Enter your billing address"
        v-model="form.address" required />
    </div>
    <div class="address-container">
      <div class="city-input">
        <label for="city">City</label>
        <input type="text" id="city" placeholder="Enter your city" v-model="form.city" required />
      </div>
      <div class="state-input">
        <label for="state">State</label>
        <select id="state" name="state" v-model="form.state">
          <option v-for="(state, index) of States" :key="index" :value="state">
            {{ state }}
          </option>
        </select>
      </div>
      <div class="zip-input">
        <label for="zip">Zip</label>
        <input type="text" id="zip" placeholder="12345" v-model="form.zip" maxlength="5" pattern="^\d{5}$"
          required />
      </div>
    </div>
    <div class="form-group">
      <label for="org">Organization</label>
      <input type="text" id="org" placeholder="Enter your organization" v-model="form.org" required />
    </div> -->
  </form>
</template>
<script setup>
import { ref, computed } from "vue";

const form = defineModel();
const showDropdown = ref(false);

const emit = defineEmits(["submitForm"]);

const States = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

const priorityStates = ["AR", "MS", "MO", "LA", "WI", "MN", "TX", "CA"];
const priority = States.filter((state) => priorityStates.includes(state));
const others = States.filter((state) => !priorityStates.includes(state));

others.sort();

const sortedStates = [...priority, ...others];


const filteredStates = computed(() => {
  const formState = form.value?.state?.toLowerCase()?.trim();

  if (!formState.length) {
    return sortedStates;
  }

  return sortedStates.filter((state) =>
    state.toLowerCase().startsWith(formState)
  );
});

const selectState = (state) => {
  form.value.state = state;
  showDropdown.value = false;
};

const onBlur = () => {
  setTimeout(() => {
    showDropdown.value = false;
  }, 200);
};
const handleSubmit = (e) => {
  emit("submitForm", form.value);
}
const markInvalidZip = (e) => {
  form.value.zip = "";
  e.target.classList.add("invalid");
 
}
const markInvalid = (e) => {
  e.target.classList.add("invalid");
}
const clearInvalid = (e) => {
  e.target.classList.remove("invalid");
}

</script>

<style lang="css" scoped>
.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--padding) var(--padding);
  padding: 0px;
}

.form-group {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: var(--padding) var(--padding);
  justify-content: stretch;
  align-items: center;
}

.form-group div {
  align-self: stretch;
  justify-self: stretch;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: var(--padding) var(--padding);
  align-items: center;
}

.dropdown-menu {
  top: calc(3px + 100%);
  left: 40px;
  margin: 0px;
  min-width: 60%;
 
  /* margin-left: calc(50% - 1px); */
  /* margin-top: 3px; */
  /* top: 100%; */
  /* right: 0px; */
  display: block;
  list-style: none;
  padding: 0px;
  border: 1px solid #ccc;
  border-radius: 0px;
  max-height: 30vh;
  overflow-y: auto;
  background-color: var(--bg3);
  position: absolute;
  z-index: 100;
}

.dropdown-menu li {
  text-align: center;
  padding: var(--padding);
  cursor: pointer;
  color: var(--fg0);
  background-color: var(--bg1);
}

.dropdown-menu li:hover {
  background-color: var(--bg2);
}

.city-div {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.state-div {
  /* for the dropdown-menu */
  flex-grow: 0 !important;
  position: relative;
  display: inline-block;
}

.zip-div {
  flex-grow: 0 !important;
}

#billing-address {
  flex-grow: 1;
}

#city {
  flex-grow: 1;
}

.update-bttn {
  margin: 1rem 0;
}
.invalid {
  border: 1px solid red !important;
  
}
.invalid:focus {
  outline: 1px solid red !important;
}

@media only screen and (max-width: 800px) {
  .contact-form-container {
    width: 100%;
  }
  .state-div {
    flex-grow: .5 !important;
  }
  .state-div input{
    width: 50%;
  }
  .dropdown-menu{
    left: 45px;
  }
}
</style>
