<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      overflow: auto;
      line-height: 2.2em;
      min-width: 27rem;
    "
  >
    <div>
      <label class="note">Auth0 Search -- For Managing Identity Linking</label>
      <br>
      <label class="note">Phone Number Search Format: +15012227777</label>
    </div>

    <div class="mb-1 search-bar">
      <label for="user-search" class="mr-1">Search:</label
      ><input
        v-model="search.searchText"
        type="text"
        id="user-search"
        placeholder="..."
        @keyup.enter="doSearch"
        autofocus
      />
    </div>

    <div v-if="search.searchResults" class="search-results">
      <Auth0User
        v-for="user in search.searchResults"
        :key="user.key"
        :user="user"
        @changed="doSearch(2750)"
      />
    </div>
  </div>
</template>

<script setup>
import Auth0User from "@/components/Auth0User.vue";
import { reactive, ref } from "vue";
import { useAdminApi } from "@/plugins/api";

const $adminApi = useAdminApi();

const search = reactive({
  searchText: "",
  searchResults: null,
});

async function doSearch(delay = 0) {
  try {
    setTimeout(async () => {
      search.searchResults = await $adminApi.getAuth0Users({
        q: search.searchText,
        search_engine: "v3",
      });
      search.searchResults.forEach((u) => {
        u.key = `${u.user_id}.${u.updated_at}.${u.identities?.length || 0}`;
      });
    }, delay);
  } catch (e) {
    console.error(e);
  }
}
</script>

<style lang="css" scoped>
.note {
  font-style: italic;
  font-size: smaller;
}

.search-bar {
  line-height: 2.2em;
}

.search-results {
  max-width: 50em;
  display: flex;
  flex-direction: column;
  row-gap: var(--padding);
}
</style>
